.buy-credits-container {
    display: flex;
    justify-content: center; /* Centrer les éléments */
    flex-wrap: wrap;
    gap: 20px; /* Espacement fixe entre les éléments */
    margin: 20px 0;
}

.credit-option {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.credit-option {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    margin: 10px; /* Ajout d'une marge pour éviter que les éléments ne soient collés */
}

.credit-option:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-color: #007BFF;
    /* Ombre plus prononcée au survol */
    border-width: 2px;
}

.credit-option h3 {
    margin: 0;
    color: #333;
    font-size: 2em;
}

.credit-option p {
    color: #666;
}

.credit-option button {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.credit-option button:hover {
    background-color: #003d82;
    border-color: #003d82;
}