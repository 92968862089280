.App {
  text-align: center;
  padding: 67px 20px 0px 200px;
  display: flex;
  flex-direction: column;
}

.slick-dots {
  position: relative;
  bottom: -15px;
  padding-bottom: 4px;
}

.slick-dots li button {
  border: 1px hsla(206,100%,73.3%,1);
  background: hsla(206,100%,73.3%,1);
  font-size: 20px;
}

.slick-dots li.slick-active button:before {
  color: #007BFF
}


@media (min-width: 600px){
.css-1yo9slo-MuiToolbar-root {
    padding-left: 18px;
    padding-right: 24px;
  }
}

h1 {
  margin: 40px 0 20px 0;
  font-size: 60px;
  font-family: math;
  background: -webkit-linear-gradient(to right, #111100, #434343);
  /* Pour Chrome et Safari */
  background: linear-gradient(to right, #111100, #434343);
  /* Syntaxe standard */
  -webkit-background-clip: text;
  /* Pour Chrome et Safari */
  background-clip: text;
  color: transparent;
  /* Rend le texte transparent pour afficher le dégradé */
  -webkit-text-fill-color: transparent;
  /* Spécifique à Safari */
}

.intro {
  max-width: 600px;
  font-size: 13px;
  color: #7c7a7a;
  margin-top: 35px;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
}

.surmain-container {
  margin-left: 20%;
  max-width: 1500px;
}

.user-choice {
  display: flex;
  justify-content: center;
  /* ou center, selon la disposition souhaitée */
}

.user-choice button {

  margin: 20px;
  width: 20%;
  max-width: 250px;
  /* cela permet aux boutons de prendre la même largeur */
  transition: all 0.4s ease;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); /* Ombre initiale pour donner de la profondeur */
  transform: translateY(0);
}

.user-choice button:hover {
  background-color: #e6a800;
  transform: translateY(-3px); /* Effet de soulèvement pour donner de la profondeur */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* Ombre plus prononcée pour l'effet de soulèvement */
}

/* définir un comportement différent pour le bouton en version mobile */
@media (max-width: 600px) {
  .user-choice button {
    width: 100%;
    /* cela permet aux boutons de prendre toute la largeur disponible */
  }
}




/*
textarea.urls-input {
  height: 180px;
  border-radius: 8px;
  border: 1px solid #ffbf0014;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffbf0014;
  font-size: 14px;
  resize: vertical;
  max-height: 180px;
  min-height: 20px;
  padding: 10px 0 5px 10px;
  max-width: 1500px;
  margin: 20px 0 20px 0;
  width: 98.5%
}
*/
.textarea-autosize {
  border-radius: 8px;
  font-size: 14px;
  /* Taille de police standard */
  line-height: 1.3;
  /* Hauteur de ligne pour la lisibilité */
  resize: vertical;
  min-height: 20px;
  max-height: 100px;
  padding: 10px;
  margin: 20px 0;
  width: 50%;
  color: #1C2025;
  /* Par défaut : mode clair */
  background: #fff;
  /* Par défaut : mode clair */
  border: 1px solid #DAE2ED;
  /* Par défaut : mode clair */
  box-shadow: 0px 2px 2px #F3F6F9;
  /* Par défaut : mode clair */
  min-width: 400px;
}

.textarea-autosize:hover {
  border-color: #3399FF;
}

.textarea-autosize:focus {
  border-color: #3399FF;
  box-shadow: 0 0 0 3px #b6daff;
  /* Ajuster selon le mode */
  outline: 0;
}

@media (prefers-color-scheme: dark) {
  .textarea-autosize {
    color: #C7D0DD;
    /* Mode sombre */
    background: #1C2025;
    /* Mode sombre */
    border: 1px solid #434D5B;
    /* Mode sombre */
    box-shadow: 0px 2px 2px #303740;
    /* Mode sombre */
  }

  .textarea-autosize:focus {
    box-shadow: 0 0 0 3px #0072E5;
    /* Mode sombre */
  }
}

.extract-ngrams {
  background-color: #FFBF00; /* Couleur de fond principale */
  color: black; 
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 35px;
  transition: all 0.3s ease;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2); /* Ombre subtile pour le bouton */
}

.extract-ngrams:hover {
  background-color: #e6a800; /* Assombrir la couleur au survol */
  transform: translateY(-2px); /* Effet de soulèvement pour donner de la profondeur */
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}

.successMessage {
  font-size: 14px;
  margin-bottom: 35px;
}

/* Ajouter une nouvelle classe ou un sélecteur pour la div englobant les boutons si nécessaire */
.top-buttons {
  flex-direction: column;
  align-items: center;
}

.return {
  background-color: #007BFF;
  color: white;
  margin-top: 10px;
  /* Vous pourriez ajuster cela pour le centrage */
  font-size: 14px;

  width: 150px;
}

.return:hover {
  background-color: #0069d9;
}

nav.author {
  align-self: flex-start;
  /* Aligner le nav.author à gauche */
  margin-top: auto;
  /* Pousse nav.author vers le bas */
  width: 90%;
  /* Prend toute la largeur pour l'alignement à gauche */
  padding-left: 10px;
  /* Ajoutez un padding pour ne pas coller au bord */
  text-align: left;
  font-size: 12.5px;
  margin-bottom: 76px;
}

nav.author a {
  color: #ffbf00;
  text-decoration: none;
}

.extract-ngrams {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.main-container {
  display: flex;
  margin-left: 0;
  transition: margin-left .5s;
}

.main-container.show-sidebar {
  margin-left: 250px;
  padding-right: 5px;
}

.toggle-button {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 10px;
  z-index: 1;
  /* pour s'assurer qu'il apparaît au-dessus du contenu de la colonne */
}

.query-input {
  padding: 14px 10px;
  margin: 20px 0 30px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  width: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
  outline: none;
}

.query-input:focus {
  border-color: #FFBF00;
  box-shadow: 0px 0px 8px rgba(255, 191, 0, 0.5);
}

/* Requête média pour les appareils avec une largeur maximale de 768px (tablettes et mobiles) */
@media (max-width: 768px) {
  .query-input {
    width: 80%; /* Ajuste la largeur à 80% sur les appareils mobiles */
  }
}

.country-selector {
  padding: 12px 10px 12px 10px;
  margin: 8px 0 25px 0;
  border: 1px solid #ccc;
  border-radius: 2px;
  font-size: 14px;
  width: 30%;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  appearance: none; /* Remove default browser styling */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path fill="%23000" d="M7.41 7.84L12.58 13 18 7.59 16.59 6.17 12.58 10.17 8.41 6 7 7.41z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.country-selector:focus {
  border-color: #FFBF00;
  box-shadow: 0px 0px 8px rgba(255, 191, 0, 0.5);
}

/* Requête média pour les appareils avec une largeur maximale de 768px (tablettes et mobiles) */
@media (max-width: 768px) {
  .country-selector {
    width: 60%; /* Ajuste la largeur à 80% sur les appareils mobiles */
  }
}

.api-key-input {
  padding: 12px 0px;
  border: 1px solid #ffbf00;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 12px;
  width: 90%;
  margin-top: 50px;
}

.openai-key-input {
  padding: 12px 0px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 12px;
  width: 90%;
  margin-top: 50px
}

.p-api-openai {
  font-size: 12px;
  width: 90%;
  text-align: left;
  margin-left: 12px;
}

.p-serpapi {
  font-size: 12px;
  width: 90%;
  text-align: left;
  margin-left: 12px;
  margin-bottom: 0
}

.p-serpapi a {
  color: #ffbf00;
}

.div-summary {
  text-align: left;
  background-color: white;
  font-size: 13px;
  padding: 10px;
  overflow-y: auto;
  margin-bottom: 40px;
  position: relative;
  resize: vertical;
  min-height: 10px;
  max-height: 600px;
  height: 200px;
  border: .5px solid #0000005c
}

.div-summary-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.div-summary-content.expanded {
  max-height: 1000px;
  /* Vous pouvez ajuster cette valeur */
  transition: max-height 1s ease-in;
}

.copy-mention {
  position: absolute;
  top: 0.4em;
  right: 0.2em;
  cursor: pointer;
  z-index: 1;
  border: #303030 solid 0.1px;
}

.return {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: auto;
  /* pousse l'icône à gauche */
}

.return span:not(.icon) {
  flex-grow: 1;
  /* permet au texte de prendre tout l'espace disponible */
  text-align: center;
  /* centre le texte dans l'espace disponible */
}

.button-flex-container {
  display: flex;
  align-items: center;
}



.ngramComponent {
  width: 100%;
  margin-left: 1px;
  font-size: 12px;
  margin-top: 20px;
}

button.downloadResult {
  padding: 10px 20px;
  background-color: #ffbf00;
  color: black;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 80px;
}

button.downloadResult:hover {
  background-color: #eeb305;
}

button.IAResumeChatbot {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 40px;
  padding: 20px;
  font-size: 18px;
}

button.IAResumeChatbot:hover {
  background-color: #0069d9;
}


/* Style pour le composant ConfirmationModal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2000;
  /* Assure que le modal est au premier plan */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  max-height: 80vh;
  /* Hauteur maximale de 80% de la hauteur de la fenêtre */
  overflow-y: auto;
  /* Permet le défilement vertical si nécessaire */
  text-align: center;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.modal-content h2 {
  text-align: center;
  padding-top: 0;
}

.modal-content-content {
  background-color: #fff;
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  max-height: 80vh;
  /* Hauteur maximale de 80% de la hauteur de la fenêtre */
  overflow-y: auto;
  text-align: center;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.mod {
  /*  display: flex;       */
  flex-direction: column;
  background-color: #fff;
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  max-height: 80vh;
  /* Hauteur maximale de 80% de la hauteur de la fenêtre */
  text-align: center;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}



.mod button {
  margin: 0 auto;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px
}

.mod input {
  margin: 0 auto;
  margin-top: 20px;
  width: 99%;
  background-color: #f8f8f8;
  height: 40px;
  border-radius: 5px;
  border: 1px solid lightgray;
}

.summaryCost {
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 14px;
}

.articleCost {
  margin-bottom: 0;
  font-size: 14px;
}


h2.messageInvalidURLs {
  color: #ffbf00;
}

.modal-buttons {
  margin-top: 20px;
}

.modal-buttons button {
  margin: 0 15px;
}

.modal-list {
  list-style: none;
  text-align: left;
  padding-left: 0;
}

.loader-extract-ngrams {
  margin-top: 20px;
}

/* Style pour le bouton 
.user-choice button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
*/

.loader-container {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}





.main-container {
  justify-content: space-between;
  /* Assurez-vous que le conteneur principal ne déborde pas */
  overflow: hidden;
}





.slider-container {
  margin-bottom: 50px;
}

.slick-dots li button:before {
  color: #007BFF;
  font-size: 20px;
}

.urlFilter {
  margin-bottom: 20px;
  margin-top: 20px;
}

button.redactionArticle {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 10px;
  padding: 20px;
  font-size: 18px;
}

button.redactionArticle:hover {
  background-color: #0069d9;
}


pre {
  overflow-x: auto;
  white-space: pre-wrap;
  text-align: left;
}


h2 {
  margin: 0;
}

.statistics {
  text-align: left;
  background-color: #007BFF;
  width: 100%;
  margin: 0 auto;
  border-radius: 5px;
}

p.title {
  color: white;
  font-size: 22px;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.menu_deroulant {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  padding-bottom: 15px;
  padding-right: 10px;
}

.statistics-content {
  overflow-y: hidden;
  max-height: 5px;
  padding-left: 10px;
  transition: max-height 0.3s ease-in-out;
}

.statistics-content.expanded {
  max-height: 450px;
  overflow-y: auto;
  background-color: #ffffff;
  /* Hauteur maximale lorsqu'il est déroulé */
  border: 1px solid #ccc;
}

.statistics:hover {
  background-color: #0056b3;
}


textarea {
  width: 97%;
  height: 100px;
  padding: 10px;
  margin: 20px 0;
  overflow-y: auto;
}

button {
  padding: 10px 20px;
  background-color: #ffbf00;
  color: black;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.return {
  background-color: #007BFF;
  color: white;
  margin-top: 50px;
  font-size: 14px;
  border-radius: 5px;
  margin-right: 2px;
}

.return:hover {
  background-color: #0069d9;
}

.returnHome {
  color: white;
  margin: 10px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.results {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}

.column {
  width: 100%;
}

h2 {
  border-bottom: 2px solid #007BFF;
  padding-bottom: 10px;
}




.ngram-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.ngram-cell {
  flex: 1;
  text-align: center;
}

/* permet d'afficher les 2 graphiques côte à côte */
.chartContainer {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chartContainer canvas {
  max-width: 50%;
  /* 50% de la largeur du conteneur pour chaque graphique */
  width: 100%;
  /* Utilisez toute la largeur disponible */
  margin-bottom: 40px;
}

.chartContainer canvas:first-child {
  margin-right: 20px;
  /* Ajoutez une marge à droite du premier graphique */
}

/* Styles de base pour le tableau */
.url-data-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  overflow-x: auto;
  /* Ajoute un défilement horizontal si nécessaire */
  display: block;
  /* Permet le défilement horizontal */

}

.url-data-table th,
.url-data-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}

.url-data-table th {
  background-color: #f2f2f2;
  text-align: center;

}

/* Media Queries pour les écrans plus grands */
@media (min-width: 768px) {

  .url-data-table td,
  .url-data-table th {
    width: 150px;
    height: 100px;
  }
}

/* Media Queries pour les écrans plus petits */
@media (max-width: 767px) {

  .url-data-table td,
  .url-data-table th {
    width: auto;
    /* Ajustez selon vos besoins */
    height: auto;
    /* Ajustez selon vos besoins */
  }
}

button.briefEdito {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  font-size: 18px;
}

button.briefEdito:hover {
  background-color: #0069d9;
}

.chatbot-response {
  resize: vertical;
  min-height: 10px;
  /* Hauteur minimale initiale */
  /* Hauteur maximale */
  max-height: 600px;
  height: 400px;
  overflow-y: auto;
}

.response-section,
.article-section {
  margin-bottom: 20px;
}

.chatbot-response title,
.chatbot-response meta,
.chatbot-response h1,
.chatbot-response h2,
.chatbot-response h3,
.chatbot-response h4,
.chatbot-response h5,
.chatbot-response h6 {
  display: inline;
  margin-bottom: 1em;
  /* Ajoute un espace en dessous de chaque balise Hn */
}

pre.article {
  background-color: #ecf3f9;
  padding: 15px;
  border-radius: 5px;
  border: #000000 solid 0.5px;
  margin-bottom: 20px;
  overflow-y: auto;
  height: 500px;
}


.redactionArticle.disabled {
  background-color: #007bff4a;
  cursor: not-allowed;
  /* Vous pouvez ajouter d'autres styles pour indiquer que le bouton est désactivé */
}

.redactionArticle.disabled:hover {
  background-color: #007bff4a
    /* Restez sur la même couleur d'arrière-plan lors du survol */
}

.redactionArticle.disabled:hover::before {
  content: "You can only start generating your SEO-optimized article once you've generated your editorial brief, because the AI uses the brief to write your article";
  position: absolute;
  width: 250px;
  font-size: 13px;
  /* Largeur en pourcentage pour une flexibilité accrue */
  background-color: #007bffa8;
  color: #000000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  z-index: 1000;
  /* Positionnez le tooltip au-dessus du bouton */
  left: 80%;
  transform: translateX(-50%);
  /* Remplace margin-left pour centrer */
}


.dashboard {
  display: flex;
  justify-content: space-around;
  height: 100vh;
  /* Utilisez 100vh pour que le tableau de bord occupe toute la hauteur de la fenêtre de visualisation */
  overflow-y: auto;
  /* Cela empêche le défilement sur le tableau de bord lui-même */
  border: 1px solid gray;
  padding: 10px;
  font-size: 12px;
  text-align: left;
}

.column {
  flex: 1;

  /* Cela permet le défilement dans chaque colonne */
  height: 100%;
  /* Cela garantit que la colonne s'étend sur toute la hauteur de son parent */
}

.column-group {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow-y: auto;
  height: calc(100% - 40px);
  /* Soustrait la hauteur de l'en-tête */
}

.column-content {
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.summary-group {
  border: 1px solid #ccc;
  padding: 10px;
  height: 250px;
  overflow-x: auto;
  font-size: 12px;
  text-align: left;
}

.column-header-dashboard {
  background-color: #f2f2f2;
  /* Couleur de fond gris clair */
  padding: 10px;
  /* Ajoute un peu d'espace autour du texte */
  text-align: center;
  /* Centre le texte */
  position: sticky;
  top: 0;
  z-index: 10;
  /* Assurez-vous que l'en-tête est au-dessus des autres éléments lorsqu'il est collant */
  border-right: 1px solid white;
  border-bottom: 1px solid black;
  background-color: #007BFF;
  color: white
}

.column-header-dashboard:hover {
  background-color: #0069d9;
}

.content-ai_summaries,
.content-ai_editorial_briefs,
.content-ai_articles {
  border: 1px solid #ccc;
  padding: 10px;
  height: 250px;
  overflow-x: auto;
  font-size: 12px;
  text-align: left;
}

.column-scrollable {
  overflow-y: auto;
  height: calc(100% - 40px);
  /* Soustrait la hauteur de l'en-tête */
}

.content-ai_summaries {
  border: 1px solid #ccc;
  padding: 10px;
  height: 250px;
  overflow-x: auto;
  /* Autorise le défilement horizontal pour les URLs longs */
  font-size: 12px;
  text-align: left;
  display: flex;
  /* Utilisé pour aligner les éléments en ligne */
  flex-direction: column;
  /* Les éléments sont empilés verticalement */
  word-break: break-all;
  /* Cela permet de casser les mots (ou URLs) trop longs */
}



/* Ajout d'une bordure pour chaque groupe de boutons */
.buttons-ai_summaries,
.buttons-ai_editorial_briefs,
.buttons-ai_articles {
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 250px;
  overflow-x: auto;
  /* Centrer verticalement les boutons */
}

.button-upload-dashboard {
  margin-bottom: 10px;
}

.buttons-ai_summaries button:hover,
.buttons-ai_editorial_briefs button:hover,
.buttons-ai_articles button:hover {
  background-color: #eeb305;
  /* Couleur de fond gris clair */
}

/* Espacement autour des boutons pour une meilleure présentation */
.dash {
  margin: 5px 0;
}

.dropdown-menu-content {
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 600px;
  max-height: 400px;
  overflow-x: auto;
  border: 1px solid #ccc;
}

.dropdown-item {
  padding: 12px 16px;
  cursor: pointer;
  display: block;
  text-align: left;

}

.dropdown-item:hover {
  background-color: #ddd;
}

li.ngrams {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.quill {
  height: 500px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.tab-extract-ngrams {
  height: 500px;
  /* overflow-y: auto; */
}


.filtres {
  margin-top: 20px;
}


.beatLoaderResume {
  margin-bottom: 40px;
}

select.languageChoice {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  /* Change le curseur pour indiquer qu'il s'agit d'un élément interactif */
}

.languageChoiceModalChatComponent {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  /* Change le curseur pour indiquer qu'il s'agit d'un élément interactif */
}

/* Style pour les options à l'intérieur du select */
select.languageChoice option {
  padding: 20px;
  /* Ajoute de l'espace autour du texte dans les options */
  background-color: #ffffff;
  /* Couleur de fond par défaut pour les options */
}

/* Change la couleur de fond des options au survol */
select.languageChoice option:hover {
  background-color: #f2f2f2;
  /* Couleur de fond au survol */
}

/* Vous pouvez également ajouter un peu de style au survol du select lui-même */
select.languageChoice:hover {
  background-color: #f8f8f8;
}

.material-symbols-outlined {
  color: black;
  font-size: 24px;
  font-variation-settings:
    'FILL' 1;
  vertical-align: bottom;

}

.material-symbols-outlined.returnhome {
  margin-right: 10px;
}

/*
.slogan {
  font-size: 14px;
  font-weight: 400;
  font-family: monospace;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 8px;
  padding: 4px 0;
  display: block;
  border: 0.5px solid #ff9500;
  text-align: center;
  width: 25%;
  margin-left: 37.5%;
}
*/
.slogan {
  font-size: 14px;
  font-weight: 400;
  font-family: monospace;
  border-radius: 5px;
  max-width: 200px;
  /* ou la largeur maximale que vous souhaitez pour votre slogan */
  margin: 0 auto;
  /* ceci centre votre slogan horizontalement */
  border: 0.5px solid #ffbf00;
  /* exemple de bordure, remplacez par ce que vous voulez */
  padding: 5px;
  /* ajustez le padding selon vos besoins */
  box-sizing: border-box;
  /* s'assure que le padding et la bordure sont inclus dans la largeur */
  margin-top: 30px;
  margin-bottom: 35px;
}



.login {
  display: block;
  /* Cela garantit que les éléments sont affichés comme des blocs, occupant leur propre espace sur une nouvelle ligne. */
  width: 100%;
  /* Cela garantit qu'ils prennent toute la largeur disponible, ajustez en fonction de vos besoins. */
}

.reactQuill {
  display: block;
  flex-direction: column;

}

.containerArticle {
  resize: vertical;
  min-height: 200px;
  max-height: 566px;
  height: 600px;
  overflow-y: auto;
}


/* Style de base pour la sidebar */
.sidebar {
  position: fixed;
  /* Fixe la sidebar sur le côté */
  left: 0;
  /* Aligné à gauche */
  top: 0;
  /* Aligné en haut */
  bottom: 0;
  /* S'étend jusqu'en bas */
  width: 170px;

  overflow-y: auto;
  /* Permettre le défilement si le contenu est long */
  z-index: 101;
  top: 0;
  /* S'assurer que la sidebar est au-dessus du contenu */
  height: 100%;
  background-color: #000000db;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 66px;
}

.button-hover {
  transition: background-color 0.3s;
  /* Transition douce pour le changement de couleur */
}

.button-hover:hover {
  background-color: #E6AC00;
  /* La couleur que vous souhaitez afficher au survol */
}

/*
.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  width: 250px;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  color: #fff;
  padding-right: 5px;
  padding-left: 5px;
}
*/

.left-section {
  width: 65%;
}

.right-section {
  width: 45%;
  padding-left: 25px;
  overflow-y: auto;
}



/* Style pour le contenu principal */
.main-content {
  /* Marge gauche égale à la largeur de la sidebar */
  min-height: 100vh;
  /* Hauteur minimale pour tout l'écran */
}


/* Media query pour les très petits écrans */
@media (max-width: 480px) {
  .sidebar {
    position: static;
    /* La sidebar n'est plus fixée */
    width: 100%;
    /* La sidebar prend la pleine largeur */
    min-width: 0;
    /* Ignorer la largeur minimale */
  }
}



.nav-link {
  color: white;
  text-decoration: none;
  margin: 0 20px;
  font: 22px 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;

}

.nav-link:hover {
  color: #add8e6;
}


.react-resizable-handle-se {
  top: 0;
}



.modals {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1001;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-contenu {
  background: white;
  width: 90%;
  /* Ajustez selon vos besoins */
  max-width: 1000px;
  /* Ajustez selon vos besoins */
  max-height: 90%;
  /* Ajustez selon vos besoins */
  overflow-y: auto;
  /* Pour la barre de défilement si nécessaire */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  /* Ajouté pour gérer le layout interne en colonnes */
  justify-content: space-between;
  /* Ajouté pour positionner le contenu et le footer */
}

.modal-sections {
  display: flex;
  /* Ceci affichera les sections de contenu côte à côte */
  width: 100%;
}

.modal-section {
  flex: 1;
  padding: 10px;
  width: 40%;
  /* On utilise calc pour prendre en compte la marge du divider */
  min-width: 0;
  /* Ceci s'assure que la flexbox respecte les largeurs de boîte */
}

.modal-divider {
  width: 1px;
  background-color: #ccc;
  margin: 0 20px;
  height: 100%;
}

.modal-text {
  overflow-y: auto;
  /* Ajoute une barre de défilement si nécessaire */
  max-height: 400px;
  /* Ajustez selon vos besoins */
  font-size: 12px;
  text-align: left;
}

.modal-footer {
  display: flex;
  justify-content: center;
  /* Ceci assure que le footer prenne toute la largeur du modal */
  padding: 20px;
  /* Ceci ajoute de l'espace autour du bouton */
}



.similarity-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-size: 0.8em;
  /* Réduire la taille de la police */
}

.similarity-table th,
.similarity-table td {
  padding: 8px;
  border: 1px solid #ddd;
}

.similarity-table th {
  background-color: #f2f2f2;
  text-align: center;
}

.similarity-table td {
  vertical-align: middle;
}

/* Aligner le texte des URLs à droite */
.similarity-table td:nth-child(1) {
  text-align: left;
}

/* Centrer le texte dans la colonne des scores */
.similarity-table td:nth-child(2) {
  text-align: center;
}

/* Ajouter des rayures zébrées au tableau */
tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.similarity-table tr:nth-child(even) {
  background-color: #ffffff;
}

/* Style pour les lignes survolées */
.similarity-table tr:hover {
  background-color: #eaeaea;
}

/* Style pour les URLs cliquables */
.similarity-table td:nth-child(1):hover {
  cursor: pointer;
  text-decoration: underline;
}



.alert-message {
  color: red;
  margin: 10px;
  padding: 5px;
  border: 1px solid red;
}


/* Ajout de styles pour le menu burger */
.burger-menu {
  display: none;
  cursor: pointer;
}

/* Styles pour le menu déroulant sur les petits écrans */
@media screen and (max-width: 600px) {
  .burger-menu {
    display: block;
  }

  .nav-container {
    display: none;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .nav-container a,
  .nav-container button {
    display: block;
    text-align: left;
  }

  .App {
    padding: 0;
  }

  .intro {
    margin: 30px 15px 20px 15px;
  }

  nav.author {
    margin-bottom: 20px;
    margin-top: 50px;
    text-align: center;
  }

  .textarea-autosize {
    min-width: 300px;
  }

  .react-resizable {
    width: 100%;
  }
}

/* Afficher le menu lorsque isNavVisible est vrai */
.nav-container.show {
  display: block;
}



.threebuttons {
  display: flex;
  margin-bottom: 10px;
}

@media (max-width: 700px) {
  .threebuttons {
    flex-direction: column;
  }

  .threebuttons button {
    margin-right: 0 !important;
    /* Supprime la marge à droite dans la vue mobile */
    margin-bottom: 10px;
    /* Ajoute un espace entre les boutons */
  }

  .threebuttons button:last-child {
    margin-bottom: 0;
    /* Supprime la marge du dernier élément pour éviter un espace inutile en bas */
  }
}

.queryButton {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.queryButton:hover {
  background-color: #0056b3;
}


.form-group {
  display: flex;
  flex-direction: row; /* Affichez les éléments en ligne */
  align-items: center; /* Centrez les éléments verticalement */
  margin-bottom: 15px; /* Espacement entre les groupes de formulaires */
}

.form-group label {
  flex: 0 0 120px; /* Ne pas étirer l'étiquette, donner une largeur fixe */
  margin-right: 20px; /* Espacement entre l'étiquette et le champ */
}

.form-group input,
.form-group textarea {
  flex: 1; /* Étirez le champ pour prendre tout l'espace disponible */
  padding: 10px;
  border: 1px solid #ccc; /* Bordure subtile */
  border-radius: 5px; /* Coins arrondis */
}

.form-group input[type="file"] {
  padding: 0; /* Réinitialisez le padding pour les sélecteurs de fichiers */
}

.main-content-admin {
  max-width: 2000px; /* Limitez la largeur pour la lisibilité */
  margin: auto; /* Centrez le contenu */
  padding: 10px;
}
